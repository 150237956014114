/* eslint-disable jsx-a11y/alt-text */
import { ThemeContext } from "@pages/_app";
import useIsMaintenanceMode from "@src/hooks/maintenance/useIsMaintenanceMode";
import useSessionHealthState from "@src/hooks/useSessionHealthState";
import UpprAffiliateTrackingNode from "@src/page-components/onboarding/external-scripts/uppr/UpprAffiliateTrackingNode";
import useIsDashboard from "@theme/hook/useIsDashboard";
import useIsLogin from "@theme/hook/useIsLogin";
import useIsMobile from "@theme/hook/useIsMobile";
import useIsOnboarding from "@theme/hook/useIsOnboarding";

import ThemeEditor from "@src/components/dashboard/debug-side-drawer/ThemeEditor";
import SoccerLogo from "@src/components/games/SoccerLogo";
import { useAsyncSelector } from "@src/hooks/useAsyncSelector";
import { useFeatureFlag } from "@src/hooks/useFeatureFlag";
import { useWindowDimensions } from "@src/hooks/useWindowDimensions";
import { devThemeConfiguationWindowState } from "@src/state/all.states";
import useIsSmallDisplay from "@src/theme/hook/useIsSmallDisplay";
import { setCookie } from "@src/utils/cookies";
import moment from "moment";
import Image from "next/image";
import { useRouter } from "next/router";
import React, { useContext, useEffect } from "react";
import defaultTheme from "smavesto.core/lib/static/themes/defaultTheme";
import styles from "./basicPageLayoutStyles.module.less";

interface BasicPageLayoutProps {
  children?: React.ReactNode | React.ReactNode[];
  headerChildren?: React.ReactNode | React.ReactNode[];
  redirectToSmavestoDeOnLogoClick?: boolean;
}

const BasicPageLayout: React.FunctionComponent<BasicPageLayoutProps> = ({
  children,
  headerChildren,
  redirectToSmavestoDeOnLogoClick
}) => {
  const { scrollHeight } = useWindowDimensions(true);

  useIsMaintenanceMode();

  useSessionHealthState();

  const router = useRouter();

  const isDashboard = useIsDashboard();

  const isOnboarding = useIsOnboarding();

  const [, displayDevThemeConfigation] = useAsyncSelector(
    devThemeConfiguationWindowState
  );

  const isLogin = useIsLogin();

  const isMobile = useIsMobile();

  const isSmallDisplay = useIsSmallDisplay();

  const { theme, cssProperties } = useContext(ThemeContext);

  const soccerFeatureFlag = useFeatureFlag("SOCCER_LOGO");

  const displaySoccerLogo = false;

  console.log("DEVDEV", moment().date() === moment("06-14-2024").date());

  useEffect(() => {
    if (router.query.snow && process.env.NEXT_PUBLIC_CLIENT_ID === "ad") {
      setCookie("snow", "on", 1);
    }
  }, [router.isReady]);

  return (
    <>
      <div
        id="page"
        style={{
          ...cssProperties,
          width: "100%",
          maxWidth: "1980px",
          margin: "auto"
        }}
        className={styles["comp-wrapper"]}
      >
        <div
          style={{
            maxWidth: "100%",
            alignItems:
              (isOnboarding || isLogin) && theme.assets.onboardingBannerImage
                ? "center"
                : "flex-start"
          }}
          className={styles["wrap-header"]}
        >
          {!isSmallDisplay && displaySoccerLogo && theme.key === "default" ? (
            <SoccerLogo
              theme={theme}
              animateIntro={router.pathname.endsWith("/login")}
              redirectToSmavestoDeOnLogoClick={redirectToSmavestoDeOnLogoClick}
            />
          ) : (
            <div
              className={styles["wrap-main-logo"]}
              style={{ width: "100%", maxWidth: isMobile ? 250 : 600 }}
            >
              <Image
                className={styles["img-logo"]}
                quality={100}
                onClick={() =>
                  redirectToSmavestoDeOnLogoClick
                    ? router.push("https://smavesto.de")
                    : router.push("/")
                }
                style={{
                  ...theme.logoStyles
                }}
                alt="SMAVESTO - Einfach anlegen"
                src={
                  (isDashboard && theme.assets.dashboardLogoImage
                    ? theme.assets.dashboardLogoImage
                    : theme.assets.logoImage) || defaultTheme.assets.logoImage
                }
                layout="intrinsic"
                objectFit="contain"
                objectPosition="center center"
                priority
                height={isDashboard ? 320 : 290}
                width={600}
              />
            </div>
          )}
          {headerChildren}
          {(isOnboarding || isLogin) && theme.assets.onboardingBannerImage && (
            <div className={styles["wrap-main-banner"]}>
              <Image
                alt="Logo"
                width={520}
                height={300}
                objectFit="contain"
                src={theme.assets.onboardingBannerImage}
                className={styles["img-banner"]}
                style={{
                  marginBottom: isMobile ? "1rem" : 0,
                  maxWidth: "100%",
                  objectFit: "contain"
                }}
              />
            </div>
          )}
        </div>
        {children}
      </div>
      {displayDevThemeConfigation && (
        <div
          style={{
            zIndex: 100,
            width: 500,
            border: "1px solid black",
            boxShadow: "0px 0px 10px rgba(0,0,0,0.1)",
            height: 400,
            borderRadius: 6,
            position: "fixed",
            padding: 10,
            right: 10,
            bottom: 10,
            background: "white",
            overflow: "scroll"
          }}
        >
          <ThemeEditor />
        </div>
      )}
      <UpprAffiliateTrackingNode />
    </>
  );
};

export default BasicPageLayout;
